import React from 'react'
import { useHistory } from 'react-router-dom'

import sprite from '../assets/img/svg/svg-sprite.svg'
import { useAppDispatch, useAppSelector } from '../store'
import { slice as authSlice } from '../store/auth'

interface Props {
  close: () => void
}

const Profile: React.FC<Props> = ({ close }) => {
  // --- EXTERNAL STATE ---//

  const history = useHistory()

  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.auth.user)

  const pushToken = useAppSelector((state) => state.auth.pushToken)

  const closeProxy = () => {
    close()
  }

  const logout = () => {
    if (pushToken) dispatch(authSlice.actions.removeTokenStart(pushToken))
    dispatch(authSlice.actions.reset())
    history.push('/login')
  }

  // --- RENDERING --- //

  return (
    <>
      <div className="overlay overlay_active" onClick={closeProxy}></div>
      <div className="popup-page__content">
        <div className="popup-page__body">
          <div className="popup-page__name">{user?.username ?? ''}</div>
          <a className="popup-page__te sl" href={`mailto://${user?.email}`}>
            {user?.email ?? ''}
          </a>
        </div>
        <div className="button__div">
          <button className="popup-page__logout" onClick={logout}>
            <svg width="24" height="24">
              <use xlinkHref={sprite + '#icon-logout'} />
            </svg>
            Выйти
          </button>
        </div>
      </div>
    </>
  )
}

export default Profile
