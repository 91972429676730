export type CornerStatus =
  | 'waiting'
  | 'confirmed'
  | 'refused'
  | 'expects'
  | 'cooking'
  | 'ready'
  | 'issued_courier'
  | 'received'
  | 'waiting_on_counter'

type CornerStatusObj = {
  [key in CornerStatus]: true
}

const cornerStatusObj: CornerStatusObj = {
  waiting: true,
  confirmed: true,
  refused: true,
  expects: true,
  cooking: true,
  ready: true,
  issued_courier: true,
  received: true,
  waiting_on_counter: true,
}

export const isCornerStatus = (val: string): val is CornerStatus => Object.keys(cornerStatusObj).includes(val)

export type OrderStatus =
  | 'waiting'
  | 'confirmed'
  | 'refused'
  | 'expects'
  | 'cooking'
  | 'ready'
  | 'issued_courier'
  | 'waiting_on_counter'
  | 'received'

type OrderStatusObj = {
  [key in OrderStatus]: true
}

const orderStatusObj: OrderStatusObj = {
  waiting: true,
  confirmed: true,
  refused: true,
  expects: true,
  cooking: true,
  ready: true,
  issued_courier: true,
  received: true,
  waiting_on_counter: true,
}

export const isOrderStatus = (val: string): val is OrderStatus => Object.keys(orderStatusObj).includes(val)

export interface OutletDescription {
  id: number
  title: string
  work_hours_start: string
  work_hours_end: string
  support_phone: string
  image: string | null
  is_available_for_orders: boolean
  market_title: string
}

export interface OutletShortDescription {
  id: number
  title: string
  image: string | null
}

export interface Option {
  id: number
  target: number
  group_id: number
  title: string
  price: number
  sort: number
  status: number
  enabled: number
}

export interface OrderItem {
  id: number
  order_id: number
  outlet_id: number
  qty_item: number
  price_item: number
  discount_percent: number
  full_price: number
  title: string
  image: string
  weight: string
  options: Option[]
  is_weight: boolean
  measure: string
  note: string
  units: string
}

export interface OutletOrderLazy {
  id: number
  order_id: number // order numbera
  // outlet_id: number
  title: string
  status: CornerStatus
  // refund_amount: number | null
  //order_items_sum: number
  //order_items_full_sum: number
  //order_items: OrderItem[]
  //outlet: OutletDescription
  isLoading: boolean
}

export type EatPlace =
  | 'car'
  | 'market_table'
  | 'market_corner'
  | 'togo_corner'
  | 'togo_courier'
  | 'togo_market'
  | 'togo_delivery'
  | 'togo_yandex'
  | 'togo_sber'
  | 'consumer'

type EatPlaceObj = {
  [key in EatPlace]: true
}

const eatPlaceObj: EatPlaceObj = {
  car: true,
  market_table: true,
  market_corner: true,
  togo_corner: true,
  togo_courier: true,
  togo_market: true,
  togo_delivery: true,
  togo_yandex: true,
  togo_sber: true,
  consumer: true,
}

export const isEatPlace = (val: string): val is EatPlace => Object.keys(eatPlaceObj).includes(val)

export interface Total {
  waiting: number
  cooking: number
  ready: number
  issued_courier: number
  received: number
  refused: number
  waiting_on_counter: number
}
export interface Orders {
  orders: OrderLazy[]
  total: Total
}

export interface Order {
  id: number
  // user_id: number
  //quantity: number
  // cost: number
  // discount_cost: number
  full_cost: number
  //discount_cost: number
  delivery_cost: number
  created_at: number
  // updated_at: number
  discriminator: string | null
  distanceFromMarketToCustomer: number | null
  name: string
  address: string | null
  phone: string
  // pay_method: null
  eat_place: EatPlace
  eats_id: string
  ready_time: number // timestamp
  ready_quickly: number // 0 or 1
  cutlery: number // '2'
  note: string
  // cart_json: string
  // status: number // 0 or 1
  // acquirer_payment: number // 0 or 1
  // user_status: number // 0 or 1
  ready_status: OrderStatus
  // rate: null
  // rate_comment: null
  // end_payment_reached: number
  // market: number // marker id
  table: number | null // table number
  // unsuitability_timestamp: null
  car_number: string | null
  market_street_title: string | null
  // market_street_id: number | null
  delivery_street: string | null
  delivery_building: string | null
  delivery_apartment: string | null
  // point_of_sale: string
  order_outlets: OutletOrder[]
}

export interface OrderLazy {
  id: number
  created_at: number
  full_cost: number
  //discount_cost: number
  delivery_cost: number
  distanceFromMarketToCustomer: number | null
  name: string
  phone: string
  eat_place: EatPlace
  eats_id: string | null
  ready_time: number // timestamp
  ready_quickly: number // 0 or 1
  // cutlery: string // '2'
  note: string
  table: number | null // table number
  ready_status: OrderStatus
  car_number: string | null
  order_outlets: OutletOrderLazy[]
}
export interface OutletOrder {
  id: number
  order_id: number // order numbera
  //outlet_id: number
  status: CornerStatus
  // refund_amount: number | null
  //order_items_sum: number
  order_items_full_sum: number
  image: string | null
  order_items: OrderItem[]
  outlet: OutletDescription
  isLoading: boolean
}
