import React from 'react'

import sprite from '../assets/img/svg/svg-sprite.svg'
import { useAppDispatch, useAppSelector } from '../store'
import { slice as marketSlice } from '../store/market'

interface Props {
  close: () => void
}

const MarketSelect: React.FC<Props> = ({ close }) => {
  // --- EXTERNAL STATE ---//

  const dispatch = useAppDispatch()
  const { list: markets, selected } = useAppSelector((state) => state.market)
  const activeMarkets = markets.filter((market) => market.is_for_yandex === 1)
  const auth = useAppSelector((state) => state.auth)

  // --- CALLBACKS --- //

  const selectMarket = (marketId: number) => () => {
    if (!auth.user) return
    const user = auth.user
    dispatch(marketSlice.actions.changeUserMarket({ userId: user.code, courierMarketId: marketId }))
    dispatch(marketSlice.actions.selectMarket(marketId))
    close()
  }

  const closeProxy = () => {
    if (selected === null) return
    close()
  }

  // --- RENDERING --- //

  return (
    <>
      <div className="overlay overlay_active" onClick={closeProxy}></div>
      <div className="choice__info">
        <div className="choice__items">
          {activeMarkets.map((market) => (
            <div className="choice__item" key={market.id} onClick={selectMarket(market.id)}>
              {market.title}
              <svg width="8" height="14">
                <use href={sprite + '#icon-arrow'} />
              </svg>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default MarketSelect
