import * as _ from 'jsonous'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { catchError, filter, map, Observable, of, switchMap, withLatestFrom } from 'rxjs'
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { decodeAndGet } from '../utils'

import type { RootState } from '.'
import { ordersDecoder } from './decoders'
import type { OrderLazy, Orders, Total } from './types'

type Status = 'idle' | 'fetching' | 'error' | 'success'

interface State {
  status: Status
  errorMessage: string | null
  list: OrderLazy[]
  total: Total
}

const initialState: State = {
  status: 'idle',
  errorMessage: null,
  list: [],
  total: {
    waiting: 0,
    cooking: 0,
    ready: 0,
    issued_courier: 0,
    received: 0,
    refused: 0,
    waiting_on_counter: 0,
  },
}

export const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    reset: (state) => {
      state.status = 'idle'
      state.errorMessage = null
      state.list = []
    },

    loadStart: (state) => {
      state.status = 'fetching'
      state.errorMessage = null
    },
    loadFail: (state, { payload }: PayloadAction<string>) => {
      state.status = 'error'
      state.errorMessage = payload
    },
    loadSuccess: (state, { payload }: PayloadAction<Orders>) => {
      state.status = 'success'
      state.errorMessage = null
      state.list = payload.orders
      state.total = payload.total
      //state.total = payload.total
    },
  },
})

// --- EPICS --- //

const BASE_URL = process.env.BASE_URL

const loadEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType('orders/loadStart'),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      ajax
        .post(
          `${BASE_URL}/arm/runner/orders`,
          { market_id: state.market.selected },
          {
            authorization: `Bearer ${state.auth.accessToken}`,
          },
        )
        .pipe(
          map(({ response }: AjaxResponse<unknown>) => {
            const [data, errorMessage] = decodeAndGet(ordersDecoder, response)
            if (data) {
              return slice.actions.loadSuccess(data)
            } else {
              return slice.actions.loadFail(errorMessage)
            }
          }),
          catchError((error: AjaxError) => {
            return of(slice.actions.loadFail(error.message))
          }),
        ),
    ),
  )

const catchOrderChange = (action$: Observable<any>) =>
  action$.pipe(
    filter(({ type }) => type === 'SOCKET/CHANGE_ORDER'),
    map(({ data }: { data: { order_id: string; status_id: string; newOrder: string } }) => {
      const orderId = Number(data.order_id)
      const newOrder = Number(data.newOrder)

      if (isNaN(orderId)) {
        if (isNaN(newOrder)) {
          return { type: 'NoOp' }
        }
      }
      return slice.actions.loadStart()
    }),
  )

export const epics = combineEpics<any, any, any, unknown>(loadEpic, catchOrderChange)
