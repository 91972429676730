import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from '../store'

interface Props {
  close: () => void
}

const OutletsList: React.FC<Props> = ({ close }) => {
  // --- EXTERNAL STATE ---//

  const history = useHistory()
  const { outletList: outlets } = useAppSelector((state) => state.market)

  // --- CALLBACKS --- //

  const closeProxy = () => {
    close()
  }

  const goToMenu = (outletId: number) => () => {
    history.push(`/menu/${outletId}`)
  }

  // --- RENDERING --- //

  return (
    <>
      <div className="overlay overlay_active" onClick={closeProxy}></div>
      <div className="choice__info">
        <div className="outlets__title">Список корнеров</div>
        <div className="choice__items">
          {outlets.map((outlet) => (
            <div className="outletlist__item" key={outlet.id}>
              <div className="outlet_row_left">
                <div className="outletlist__image" onClick={goToMenu(outlet.id)}>
                  <img src={`${outlet.image}`} alt="" />
                </div>

                <div className="outletlist__title">{outlet.title}</div>
              </div>
              <div className="outlet_row_right">
                {outlet.is_available_for_orders ? (
                  <div>
                    <div className="outlet__on">Работает</div>
                    <div className="outlet_work_hours">
                      {outlet.work_hours_start} - {outlet.work_hours_end}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="outlet__nan">СТОП</div>
                    <div className="outlet_work_hours_off">
                      {outlet.work_hours_start} - {outlet.work_hours_end}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default OutletsList
