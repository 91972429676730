import Decoder, * as _ from 'jsonous'
import { err, ok } from 'resulty'

import { nullOr, objectArray, optionalField } from '../utils'

import {
  CornerStatus,
  EatPlace,
  isCornerStatus,
  isEatPlace,
  isOrderStatus,
  Option,
  Order,
  OrderItem,
  OrderLazy,
  Orders,
  OrderStatus,
  OutletDescription,
  OutletOrder,
  OutletOrderLazy,
  OutletShortDescription,
  Total,
} from './types'

export const outletDecoder: Decoder<OutletDescription> = _.succeed({})
  //.assign('id', _.field('id', _.number))
  .assign('title', _.field('title', _.string))
  .assign('work_hours_start', _.field('work_hours_start', _.string))
  .assign('work_hours_end', _.field('work_hours_end', _.string))
  .assign('support_phone', _.field('support_phone', _.string))
  .assign('image', _.field('image', nullOr(_.string)))
  .assign('market_title', _.field('market_title', _.string))
  .assign('is_available_for_orders', _.field('is_available_for_orders', _.boolean))

export const outletShortDecoder: Decoder<OutletShortDescription> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('title', _.field('title', _.string))
  .assign('image', _.field('image', nullOr(_.string)))

export const optionDecoder: Decoder<Option> = _.succeed({})
  .assign('id', _.field('id', _.number))
  // .assign('target', _.field('target', _.number))
  // .assign('group_id', _.field('group_id', _.number))
  .assign('title', _.field('title', _.string))
  .assign('price', _.field('price', _.number))
// .assign('sort', _.field('sort', _.number))
// .assign('status', _.field('status', _.number))
// .assign('enabled', _.field('enabled', _.number))

export const orderItemDecoder: Decoder<OrderItem> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('order_id', _.field('order_id', _.number))
  //.assign('product_id', _.field('id', _.number))
  .assign('outlet_id', _.field('outlet_id', _.number))
  .assign('qty_item', _.field('qty_item', _.number))
  .assign('price_item', _.field('price_item', _.number))
  .assign('discount_percent', _.field('discount_percent', _.number))
  .assign('full_price', _.field('full_price', _.number))
  .assign('title', _.field('title', _.string))
  .assign('image', _.field('image', _.string))
  .assign('weight', _.field('weight', _.string))
  .assign('options', _.field('options', _.array(optionDecoder)))
  .assign('measure', _.field('measure', _.string))
  .assign('note', _.field('note', _.string))
  .assign('units', _.field('units', _.string))
  .assign('is_weight', _.field('is_weight', _.number))

export const outletOrderLazyDecoder: Decoder<OutletOrderLazy> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('order_id', _.field('order_id', _.number))
  //.assign('outlet_id', _.field('outlet_id', _.number))
  .assign(
    'status',
    _.field(
      'status',
      new Decoder<CornerStatus>((val: unknown) =>
        typeof val === 'string' && isCornerStatus(val) ? ok(val) : err('expect corner status'),
      ),
    ),
  )
  .assign('title', _.field('title', _.string))
  .assign('isLoading', _.succeed<boolean>(false))

export const outletOrderDecoder: Decoder<OutletOrder> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('order_id', _.field('order_id', _.number))
  .assign('outlet_id', _.field('outlet_id', _.number))
  .assign('image', _.field('image', nullOr(_.string)))
  .assign(
    'status',
    _.field(
      'status',
      new Decoder<CornerStatus>((val: unknown) =>
        typeof val === 'string' && isCornerStatus(val) ? ok(val) : err('expect corner status'),
      ),
    ),
  )
  //.assign('order_items_sum', _.field('order_items_sum', _.number))
  .assign('order_items_full_sum', _.field('order_items_full_sum', _.number))
  .assign('order_items', _.field('order_items', _.array(orderItemDecoder)))
  .assign('outlet', _.field('outlet', outletShortDecoder))
  //.assign('isLoading', _.succeed<boolean>(false))

export const orderLazyDecoder: Decoder<OrderLazy> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('created_at', _.field('created_at', _.number))
  //.assign('quantity', _.field('quantity', _.number))
  .assign('full_cost', _.field('full_cost', _.number))
  //.assign('discount_cost', _.field('discount_cost', _.number))
  .assign('delivery_cost', _.field('delivery_cost', _.number))
  .assign('distanceFromMarketToCustomer', _.field('distanceFromMarketToCustomer', nullOr(_.number)))
  .assign('name', _.field('name', _.string))
  .assign('phone', _.field('phone', _.string))
  .assign(
    'eat_place',
    _.field(
      'eat_place',
      new Decoder<EatPlace>((val: unknown) =>
        typeof val === 'string' && isEatPlace(val) ? ok(val) : err('expect eat place'),
      ),
    ),
  )
  .assign('eats_id', _.field('eats_id', nullOr(_.string)))
  .assign('ready_time', _.field('ready_time', _.number))
  .assign('ready_quickly', _.field('ready_quickly', _.number))
  //.assign('cutlery', _.field('cutlery', _.string))
  .assign('note', _.field('note', _.string))
  .assign('table', _.field('table', nullOr(_.string)))
  .assign(
    'ready_status',
    optionalField(
      'ready_status',
      new Decoder<OrderStatus>((val: unknown) =>
        typeof val === 'string' && isOrderStatus(val) ? ok(val) : err('expect order status'),
      ),
      'waiting',
    ),
  )
  .assign('car_number', _.field('car_number', nullOr(_.string)))
  //.assign('delivery_street', _.field('delivery_street', nullOr(_.string)))
  //.assign('delivery_building', _.field('delivery_building', nullOr(_.string)))
  //.assign('delivery_apartment', _.field('delivery_apartment', nullOr(_.string)))
  .assign('order_outlets', _.field('order_outlets', _.array(outletOrderLazyDecoder)))

export const orderDecoder: Decoder<Order> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('created_at', _.field('created_at', _.number))
  //.assign('quantity', _.field('quantity', _.number))
  .assign('full_cost', _.field('full_cost', _.number))
  //.assign('discount_cost', _.field('discount_cost', _.number))
  .assign('delivery_cost', _.field('delivery_cost', _.number))
  .assign('name', _.field('name', _.string))
  .assign('phone', _.field('phone', _.string))
  .assign('address', optionalField('address', _.string, 'нет адреса'))
  .assign('discriminator', optionalField('discriminator', nullOr(_.string), null))
  .assign('distanceFromMarketToCustomer', _.field('distanceFromMarketToCustomer', nullOr(_.number)))
  .assign(
    'eat_place',
    _.field(
      'eat_place',
      new Decoder<EatPlace>((val: unknown) =>
        typeof val === 'string' && isEatPlace(val) ? ok(val) : err('expect eat place'),
      ),
    ),
  )
  .assign('eats_id', _.field('eats_id', nullOr(_.string)))
  .assign('ready_time', _.field('ready_time', _.number))
  .assign('ready_quickly', _.field('ready_quickly', _.number))
  .assign('cutlery', _.field('cutlery', _.number))
  .assign('note', _.field('note', _.string))
  .assign('table', _.field('table', nullOr(_.string)))
  .assign(
    'ready_status',
    _.field(
      'ready_status',
      new Decoder<OrderStatus>((val: unknown) =>
        typeof val === 'string' && isOrderStatus(val) ? ok(val) : err('expect order status'),
      ),
    ),
  )
  .assign('car_number', _.field('car_number', nullOr(_.string)))
  .assign('market_street_title', optionalField('market_street_title', _.string, 'улица не выбрана'))
  .assign('delivery_street', _.field('delivery_street', nullOr(_.string)))
  .assign('delivery_building', _.field('delivery_building', nullOr(_.string)))
  .assign('delivery_apartment', _.field('delivery_apartment', nullOr(_.string)))
  .assign('order_outlets', _.field('order_outlets', _.array(outletOrderDecoder)))

export const totalDecoder: Decoder<Total> = _.succeed({})
  .assign('waiting', optionalField('waiting', _.number, 0))
  .assign('cooking', optionalField('cooking', _.number, 0))
  .assign('ready', optionalField('ready', _.number, 0))
  .assign('issued_courier', optionalField('issued_courier', _.number, 0))
  .assign('received', optionalField('received', _.number, 0))
  .assign('refused', optionalField('refused', _.number, 0))
  .assign('waiting_on_counter', optionalField('waiting_on_counter', _.number, 0))

export const ordersDecoder: Decoder<Orders> = _.succeed({})
  .assign('orders', _.field('orders', _.array(orderLazyDecoder)))
  .assign('total', _.field('total', totalDecoder))
