import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import { store } from '../store'

import Loader from './Loader'
import Login from './Login'
import Market from './Market'
import Order from './Order'
import RefreshToken from './RefreshToken'
import MarketSlider from './SliderTest'

const App = (): JSX.Element => (
  <Provider store={store}>
    <Router>
      <RefreshToken>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/market">
            <Market />
          </Route>
          <Route path="/loader">
            <Loader />
          </Route>
          <Route path="/order/:orderId">
            <Order />
          </Route>
          <Route path="/markets">
            <MarketSlider />
          </Route>
          <Redirect to="/market" />
        </Switch>
      </RefreshToken>
    </Router>
  </Provider>
)

export default App
