import { combineEpics, ofType } from 'redux-observable'
import { combineLatest, filter, fromEvent, map, Observable } from 'rxjs'
import { io } from 'socket.io-client'

const connection = io('wss://api.hungry.ninja', {
  path: '/pusher',
  transports: ['websocket', 'polling'],
  autoConnect: false,
})

const socketAction$: Observable<any> = fromEvent(connection, 'action')

const socketRegisterEpic$ = (action$: Observable<any>) =>
  combineLatest(
    [action$.pipe(filter(({ type }) => type === 'SOCKET/CONNECTED')), action$.pipe(ofType('market/selectMarket'))],
    (_, { payload }: { payload: number }) => {
      const marketId = payload

      if (marketId) connection.emit('action', { type: 'SOCKET/SET_RUNNER', payload: { market: marketId } })

      return false
    },
  ).pipe(filter(() => false))

const socketConnectEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType('auth/loginSuccess'),
    map(() => {
      connection.connect()
      return false
    }),
    filter(() => false),
  )
}

const socketDisconnectEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType('auth/reset'),
    map(() => {
      connection.disconnect()
      return false
    }),
    filter(() => false),
  )
}

const socketEpic$ = () => socketAction$

export const epics = combineEpics(socketRegisterEpic$, socketEpic$, socketConnectEpic, socketDisconnectEpic)
