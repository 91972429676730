import Decoder, * as _ from 'jsonous'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { catchError, filter, map, Observable, of, switchMap, take, withLatestFrom } from 'rxjs'
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { decodeAndGet } from '../utils'

import { OutletDescription } from './../../../arm.corner/src/store/types'
import type { RootState } from '.'
import { outletDecoder } from './decoders'

type Status = 'idle' | 'fetching' | 'error' | 'success'

export interface Market {
  id: number
  title: string
  status: number
  is_for_yandex: number
}

interface State {
  selected: number | null
  list: Market[]
  status: Status
  errorMessage: string | null
  outletList: OutletDescription[]
}

interface ChangeMarket {
  userId: number
  courierMarketId: number
}

const selectedItem = localStorage.getItem('marketId')

const selected = selectedItem ? Number(selectedItem) : null

const initialState: State = {
  selected: selected && !isNaN(selected) ? selected : null,
  status: 'idle',
  errorMessage: null,
  list: [],
  outletList: [],
}

export const slice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    loadStart: (state) => {
      state.status = 'fetching'
      state.errorMessage = null
    },
    loadFail: (state, { payload }: PayloadAction<string>) => {
      state.status = 'error'
      state.errorMessage = payload
    },
    loadSuccess: (state, { payload }: PayloadAction<Market[]>) => {
      state.status = 'success'
      state.errorMessage = null
      state.list = payload
    },

    selectMarket: (state, { payload }: PayloadAction<number>) => {
      state.selected = payload
      localStorage.setItem('marketId', `${payload}`)
    },

    loadOutlets: (state, _: PayloadAction<number>) => {
      state.status = 'fetching'
      state.errorMessage = null
    },

    loadOutletsSuccess: (state, { payload }: PayloadAction<OutletDescription[]>) => {
      state.status = 'success'
      state.outletList = payload
    },
    loadOutletsFail: (state, { payload }: PayloadAction<string>) => {
      state.status = 'error'
      state.errorMessage = payload
    },
    changeUserMarket: (_, __: PayloadAction<ChangeMarket>) => {
      return
    },
  },
})

// --- EPICS --- //

const BASE_URL = process.env.BASE_URL

const loadEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType('market/loadStart'),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      ajax
        .get(`${BASE_URL}/arm/runner/markets`, {
          authorization: `Bearer ${state.auth.accessToken}`,
        })
        .pipe(
          map(({ response }: AjaxResponse<unknown>) => {
            const [data, errorMessage] = decodeAndGet(_.array(marketsDecoder), response)

            if (data) {
              return slice.actions.loadSuccess(data)
            } else {
              return slice.actions.loadFail(errorMessage)
            }
          }),
          catchError((error: AjaxError) => of(slice.actions.loadFail(error.message))),
        ),
    ),
  )

const initialSelect = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType('market/loadSuccess'),
    withLatestFrom(state$),
    map(([, state]) => state.market.selected),
    filter((selected) => selected !== null),
    map((selected) => (typeof selected === 'number' ? slice.actions.selectMarket(selected) : { type: 'NoOp' })),
  )

const loadOutletsEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType('market/selectMarket'),
    switchMap(({ payload }: { payload: number }) =>
      ajax.get(`${BASE_URL}/arm/markets/outlets/${payload}`).pipe(
        map(({ response }: AjaxResponse<unknown>) => {
          const [data, errorMessage] = decodeAndGet(_.array(outletDecoder), response)

          if (data) {
            return slice.actions.loadOutletsSuccess(data)
          } else {
            return slice.actions.loadOutletsFail(errorMessage)
          }
        }),
        catchError((error: AjaxError) => of(slice.actions.loadFail(error.message))),
      ),
    ),
  )

const changeUserMarketEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType('market/changeUserMarket'),
    withLatestFrom(state$),
    switchMap(([action, state]: [{ payload: ChangeMarket }, RootState]) =>
      ajax
        .post(
          `${BASE_URL}/arm/runner/market_change`,
          {
            market_id: action.payload.courierMarketId,
          },
          {
            authorization: `Bearer ${state.auth.accessToken}`,
          },
        )

        .pipe(
          catchError((error: AjaxError) => {
            console.log('Ошибка или баг', error)
            return of(false)
          }),
          filter(() => false),
        ),
    ),
  )

export const epics = combineEpics<any, any, any, unknown>(
  loadEpic,
  loadOutletsEpic,
  initialSelect,
  changeUserMarketEpic,
)

// --- DECODERS --- //

export const marketsDecoder: Decoder<Market> = _.succeed({})
  .assign('id', _.field('id', _.number))
  .assign('title', _.field('title', _.string))
  .assign('status', _.field('status', _.number))
  .assign('is_for_yandex', _.field('is_for_yandex', _.number))
