import React from 'react'

import balchuk from '../assets/img/svg/balchuk.svg'
import cherem from '../assets/img/svg/cherem.svg'
import danilovsky from '../assets/img/svg/danilovsky.svg'
import druzhba from '../assets/img/svg/druzhba.svg'
import lp108 from '../assets/img/svg/lp108.svg'
import maroseyka from '../assets/img/svg/maroseyka.svg'
import privoz from '../assets/img/svg/privoz.svg'
import salaris from '../assets/img/svg/salaris.svg'
import streat from '../assets/img/svg/streat.svg'

const MarketSlider: React.FC = () => {
  // --- EXTERNAL STATE --- //

  // --- LOCAL STATE --- //

  // --- CALLBACKS --- //

  // --- EFFECTS --- //

  // --- RENDERING --- //

  return (
    <div className="page__container">
      <div className="page__content">
        <div className="market">
          <div className="market__header">
            <div className="market__title">
              <div className="headerContainer">
                <span>Список рынков</span>
              </div>
            </div>
          </div>
          <div className="market__items">
            <div className="markets__row">
              <div className="markets__left">
                <img src={lp108} alt="22" />
              </div>
              <div className="markets__right">
                <img src={maroseyka} alt="22" />
              </div>
            </div>
            <div className="markets__row">
              <div className="markets__left">
                <img src={balchuk} alt="22" />
              </div>
              <div className="markets__right">
                <img src={salaris} alt="22" />
              </div>
            </div>
            <div className="markets__row">
              <div className="markets__left">
                <img src={danilovsky} alt="22" />
              </div>
              <div className="markets__right">
                <img src={cherem} alt="22" />
              </div>
            </div>
            <div className="markets__row">
              <div className="markets__left">
                <img src={druzhba} alt="22" />
              </div>
              <div className="markets__right">
                <img src={privoz} alt="22" />
              </div>
            </div>
            <div className="markets__row">
              <div className="markets__left">
                <img src={streat} alt="22" />
              </div>
              <div className="markets__right">
                <img src={privoz} alt="22" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketSlider
