import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import miniloader from '../assets/img/svg/miniloader.svg'
import sprite from '../assets/img/svg/svg-sprite.svg'
import { useAppDispatch, useAppSelector } from '../store'
import { slice as orderSlice } from '../store/order'
import type { CornerStatus, Order } from '../store/types'
import { parseEatPlace, translateToStyleCorner } from '../utils'

import Loader from './Loader'

const getTime = (time: number) => {
  const date = new Date(time * 1000)

  const hours = date.getHours()
  const minutes = `0${date.getMinutes()}`

  return `${hours}:${minutes.substr(-2)}`
}

const totalQty = (order: Order) =>
  order.order_outlets.reduce((totalQty, outlet) => outlet.order_items.length + totalQty, 0)

const Order = () => {
  // --- EXTERNAL STATE --- //

  const history = useHistory()

  const { orderId } = useParams<{ orderId?: string }>()

  const dispatch = useAppDispatch()

  const order = useAppSelector((state) => state.order.data)

  console.log(order?.full_cost)
  console.log(order?.delivery_cost)

  // --- CONSTATNTS --- //

  const listIssuedCourier = order ? order.order_outlets.filter((outlet) => outlet.status === 'issued_courier') : []

  // --- CALLBACKS --- //

  const goBack = () => {
    history.push('/market')
  }

  const updateCornerStatus = (outletOrderId: number, status: CornerStatus) => () => {
    dispatch(orderSlice.actions.updateCornerStatusStart({ outletOrderIdList: [outletOrderId], status }))
  }

  const updateOrderStatus = React.useCallback(() => {
    if (!order) return
    // playSound(sound)
    if (listIssuedCourier.length === 0) return

    const listIssuedCourierId = listIssuedCourier.map((outlet) => outlet.id)

    dispatch(orderSlice.actions.updateCornerStatusStart({ outletOrderIdList: listIssuedCourierId, status: 'received' }))

    const unproceedOutlets = order.order_outlets.filter(
      (outlet) => outlet.status !== 'received' && !listIssuedCourierId.includes(outlet.id),
    )

    if (unproceedOutlets.length > 0) return

    //dispatch(orderSlice.actions.updateOrderStatusStart())
  }, [order])

  // --- EFFECTS --- //

  React.useEffect(() => {
    if (!orderId) return

    dispatch(orderSlice.actions.loadStart(parseInt(orderId)))
  }, [orderId])

  // --- RENDERING --- //

  console.log(order)

  return order ? (
    <div className="page__container">
      <div className="page__content">
        <div className="order">
          <div className="order__title">
            <svg width="12" height="14" onClick={goBack}>
              <use href={sprite + '#icon-back'} />
            </svg>
            <span>{`ЗАКАЗ №${order?.id} (${getTime(order.created_at)})`}</span>
            <div className="order__logo">
              <svg width="24" height="24"></svg>
            </div>
          </div>
          <div className="order__table">
            <div className="order__row">
              <div className="order__col order__col-name">Клиент</div>
              <div className="order__col order__col-value order__client">
                {order.name ? <span>{order.name}</span> : null}
                {order.phone ? <a href={`tel:${order.phone.replace(/\D/g, '')}`}>{order.phone}</a> : null}
              </div>
            </div>
            <div className="order__row">
              <div className="order__col order__col-name">Где будет есть</div>
              <div className="order__col order__col-value">
                {parseEatPlace(order.eat_place, order.car_number, order.table)}
              </div>
            </div>
            {order.eat_place === 'togo_delivery' ? (
              <div className="order__row">
                <div className="order__col order__col-name">Адрес</div>
                <div className="order__col order__col-value">
                  {order.delivery_street}, д.{order.delivery_building}, кв.{order.delivery_apartment}
                </div>
              </div>
            ) : null}
            {order.eat_place === 'togo_yandex' ? (
              <div className="order__row">
                <div className="order__col order__col-name">Номер Яндекса</div>
                <div className="order__col order__col-value">{order.eats_id}</div>
              </div>
            ) : null}
            {order.eat_place === 'togo_sber' ? (
              <div className="order__row">
                <div className="order__col order__col-name">Номер СберМаркет</div>
                <div className="order__col order__col-value">{order.eats_id}</div>
              </div>
            ) : null}
            {order.eat_place === 'consumer' ? (
              <div className="order__row">
                <div className="order__col order__col-name">Номер Gremm</div>
                <div className="order__col order__col-value">{order.eats_id}</div>
              </div>
            ) : null}
            {order.discriminator === 'marketplace' ? (
              <div className="order__row">
                <div className="order__col order__col-name">Адрес доставки</div>
                <div className="order__col order__col-value">{order.address}</div>
              </div>
            ) : null}
            {order.eat_place === 'car' ? (
              <div className="order__row">
                <div className="order__col order__col-name">К выходу</div>
                <div className="order__col order__col-value">{order.market_street_title}</div>
              </div>
            ) : null}
            <div className="order__row">
              <div className="order__col order__col-name">Количество приборов</div>
              <div className="order__col order__col-value">{order.cutlery}</div>
            </div>
            <div className="order__row">
              <div className="order__col order__col-name">Приготовить</div>
              <div className="order__col order__col-value">
                {order.ready_quickly === 1 ? 'как можно скорее' : `к ${getTime(Number(order.ready_time))}`}
              </div>
            </div>
            <div className="order__row">
              <div className="order__col order__col-name">Комментарий</div>
              <div className="order__col order__col-value">{order.note}</div>
            </div>
          </div>
          <div className="order__total">
            <div className="order__subtotal1">
              <div className="order__total-text">ИТОГО</div>
              <div className="order__total-sum">
                {totalQty(order)} поз. ({order.full_cost - order.delivery_cost} ₽)
              </div>
            </div>
            {order.delivery_cost > 0 ? (
              <div className="order__subtotal2">
                <div className="order__col order__col-value"> и доставка {order.delivery_cost} ₽</div>
              </div>
            ) : null}
          </div>

          <div className="order__items">
            {order.order_outlets.map(({ id, status, outlet, order_items, order_items_full_sum, isLoading }) => (
              <div className="order__item order__item_active" key={id}>
                <div className="order__header">
                  <div className="order__name">
                    <div className="order__header-img">
                      <img src={`${outlet.image}`} alt="" />
                    </div>
                    <div className="order__header-name">{outlet.title}</div>
                  </div>
                  {!['market_corner', 'togo_corner'].includes(order.eat_place) && status === 'ready' ? (
                    <button
                      className="order__header-btn order__status_readybtn"
                      onClick={updateCornerStatus(id, 'issued_courier')}
                      disabled={isLoading}
                    >
                      {isLoading ? <img src={miniloader} width="22px" height="22px" /> : <span>Забрать</span>}
                    </button>
                  ) : (
                    <div className={`order__header-btn order__status_${status}`}>
                      {translateToStyleCorner(status) || status}
                    </div>
                  )}
                </div>
                <div className="order__body">
                  {order_items.map((item, index) => (
                    <div className="order__body-item" key={item.id}>
                      <div className="order__body-row">
                        <img className="card__img" src={`${item?.image}`} width="30px" height="30px" />
                        <div className="order__position">
                          <div className="order__dish">{item.title}</div>
                          <div className="order__weight">
                            {Number(item.measure)} {item.units === 'g' ? 'гр' : item.units === 'ml' ? 'мл' : ' '}
                          </div>
                        </div>
                        <div className="order__pricesum">
                          <div className="order__number">{item.qty_item}</div>
                          <div className="order__itemprice">{item.price_item / (1 - item.discount_percent)}₽</div>
                          <div className="order__sum order__sum_md">
                            {Math.round(item.qty_item * (item.price_item / (1 - item.discount_percent)) * 10) / 10}₽
                          </div>
                        </div>
                      </div>
                      {item.note ? (
                        <div className="order__itemnote">
                          <span className="note__title">От клиента: </span>
                          {item.note}
                        </div>
                      ) : null}

                      {item.options.map((option) => (
                        <div className="order__body-row" key={option.id}>
                          <div className="order__num"></div>
                          <div className="order__dish-extra">+ {option.title}</div>
                          <div className="order__extra-pricesum">
                            <div className="order__number-extra">{item.qty_item}</div>
                            <div className="order__sum-extra">{option.price} ₽</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                  <div className="order__footer">
                    <div className="order__body-row">
                      <div className="order__num"></div>
                      <div className="order__dish"></div>
                      <div className="order__pricesum">
                        <div className="order__number">
                          ИТОГО:
                          {/* {order_items.reduce((count, item) => count + item.qty_item, 0)}шт. */}
                        </div>
                        <div className="order__sum">{Math.round(order_items_full_sum * 10) / 10} ₽</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {listIssuedCourier.length > 0 ? (
            <div className="order__button-wrap">
              <button
                className="order__button order__button_accept"
                type="button"
                disabled={listIssuedCourier.length === 0}
                onClick={updateOrderStatus}
              >
                {/* TODO вот тут иконка, похожая на чек */}
                <svg width="23" height="17">
                  <use href={sprite + '#icon-check'} />
                </svg>
                Отдать заказ клиенту
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null
}

export default Order
